<template>
  <div class="bodyBg-image">
    <img src="@/assets/img/img3.png" class="img3">
    <my-header></my-header>
    <div class="pr pt40">
      <div class="w1200 box plr25">
        <mytitle title="胃健康中医评估"></mytitle>
        <el-form ref="form" :model="formData" label-width="95px">
          <el-form-item>
            <div class="f18 c333 pb20">[多选题] 是否有以下症状</div>
            <el-checkbox-group v-model="formData.wt" @change="onWtChange">
              <el-checkbox :label="item.value" v-for="(item, index) in wt" :key="index" name="type">{{item.text}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item v-show="wtcdShow">
            <div class="f18 c333 pb20">[单选题] 胃痛程度</div>
            <el-radio-group v-model="formData.wtcd">
              <el-radio :label="item.value" v-for="(item, index) in wzcd" :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="wzcdShow">
            <div class="f18 c333 pb20">[单选题] 胃胀程度？</div>
            <el-radio-group v-model="formData.wzcd">
              <el-radio :label="item.value" v-for="(item, index) in wzcd" :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <div class="f18 c333 pb20">[多选题] 是否有以下症状？</div>
            <el-checkbox-group v-model="formData.zzfour" @change="onZzfourChange">
              <el-checkbox :label="item.value" v-for="(item, index) in zzfour" :key="index" name="type">{{item.text}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item v-show="aqcdShow">
            <div class="f18 c333 pb20">[单选题] 嗳气程度？</div>
            <el-radio-group v-model="formData.aqcd">
              <el-radio :label="item.value" v-for="(item, index) in aqcd" :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="kgcdShow">
            <div class="f18 c333 pb20">[单选题] 口干程度？</div>
            <el-radio-group v-model="formData.kgcd">
              <el-radio :label="item.value" v-for="(item, index) in kgcd" :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="fscdShow">
            <div class="f18 c333 pb20">[单选题] 反酸程度？</div>
            <el-radio-group v-model="formData.fscd">
              <el-radio :label="item.value" v-for="(item, index) in fscd" :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="kkcdShow">
            <div class="f18 c333 pb20">[单选题] 口苦程度？</div>
            <el-radio-group v-model="formData.kkcd">
              <el-radio :label="item.value" v-for="(item, index) in kkcd" :key="index">{{item.text}}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <div class="f18 c333 pb20">[多选题] 是否有以下症状？</div>
            <el-checkbox-group v-model="formData.zznine" @change="onZznineChange">
              <el-checkbox :label="item.value" v-for="(item, index) in zznine" :key="index" name="type">{{item.text}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item>
            <div class="f18 c333 pb20">[多选题] 是否有以下症状？</div>
            <el-checkbox-group v-model="formData.zzten" @change="onZztenChange">
              <el-checkbox :label="item.value" v-for="(item, index) in zzten" :key="index" name="type">{{item.text}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item>
            <div class="f18 c333 pb20">上传舌象照片</div>
            <div class="flex" style="width: 100%">
              <upimage v-model="formData.img" type="img" @upload="uploadImg" @remove="removeImg"></upimage>
              <div class="shili">
                <img src="@/assets/img/shili.png" alt="">
                <span>示例图</span>
              </div>
            </div>
          </el-form-item>
            
          <el-form-item>
            <el-button type="primary" round class="btn f20 cfff" @click="subilt">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      
    </div>
  </div>
</template>

<script>
import { setItem } from '@/assets/js/storage'
export default {
  name:'',
  data(){
    return {
      submitBtn: false,
      formData: {
        wt: [],
        wtcd: '',
        wzcd: '',
        zzfour: [],
        aqcd: '',
        kgcd: '',
        fscd: '',
        kkcd: '',
        zznine: [],
        zzten: [],
        img: ''
      },
      oldWt: '',
      oldZzfour: '',
      oldZznine: '',
      oldZzten: '',
      wtcdShow: false,
      wzcdShow: false,
      aqcdShow: false,
      kgcdShow: false,
      fscdShow: false,
      kkcdShow: false,
      wt: [{text: '胃隐痛',value: '1'}, {text: '胃胀痛',value: '2'}, {text: '胃刺痛',value: '3'}, {text: '胃灼痛',value: '4'}, {text: '胃胀气',value: '5'}, {text: '无',value: '0'}],
      wtcd: [{text: '轻微疼痛，＞3d发作1次， 时作时止',value: '1'}, {text: '疼痛明显但可忍，2-3d发作1次，发作频繁',value: '2'}, {text: '疼痛剧烈难忍，每天发作',value: '3'}],
      wzcd: [{text: '轻微胀满，＞3d发作1次， 时作时止',value: '1'}, {text: '胀满较重，有难受感但可忍，2-3d发作1次，发作频繁',value: '2'}, {text: '胀满极重，难以忍受，甚至无法弯腰，每天发作',value: '3'}],
      zzfour: [{text: '打嗝（嗳气）',value: '1'}, {text: '反酸',value: '2'}, {text: '口干',value: '3'}, {text: '口苦',value: '4'}, {text: '口臭',value: '5'}, {text: '无',value: '0'}],
      aqcd: [{text: '偶有，<4次/天',value: '1'}, {text: '时有，4-10次/天',value: '2'}, {text: '频频，>10次/天',value: '3'}],
      kgcd: [{text: '唾液减少， 口腔偏干， 可不饮水',value: '1'}, {text: '唾液明显减少， 口腔发干， 稍欲饮水',value: '2'}, {text: '口味甚苦， 食欲常减， 或伴恶吐， 需漱口 或食甜方舒， 终日如此',value: '3'}],
      fscd: [{text: '偶有，<4次/天',value: '1'}, {text: '时有，4-10次/天',value: '2'}, {text: '频频，>10次/天',value: '3'}],
      kkcd: [{text: '口中似有苦味， 偶发',value: '1'}, {text: '口中发苦， 历时较久， 食欲可减， 常发',value: '2'}, {text: '唾液甚少， 口腔黏膜身甚干燥， 常欲 饮水',value: '3'}],
      zznine: [{text: '大便干结',value: '1'}, {text: '大便稀溏',value: '2'}, {text: '大便粘滞',value: '3'}, {text: '无',value: '0'}],
      zzten: [{text: '畏寒肢冷',value: '1'}, {text: '食欲不振',value: '2'}, {text: '胁肋疼痛',value: '3'}, {text: '泛吐清水',value: '4'}, {text: '神倦乏力',value: '5'}, {text: '心烦易怒',value: '6'}, {text: '胃部喜温（喜热敷、喜热饮）',value: '7'}, {text: '胃部喜冷（喜冷食）',value: '8'}, {text: '无',value: '0'}]
    }
  },
  computed: {
    token(){
      return this.$store.state.token;
    }
  },
  mounted(){

  },
  methods: {
    async subilt(){
      if(this.submitBtn) return;
      
      if(this.formData.wt.length == 0) return this.$message.error('您填写的资料不完善');
      if(this.formData.zzfour.length == 0) return this.$message.error('您填写的资料不完善');
      if(this.formData.zznine.length == 0) return this.$message.error('您填写的资料不完善');
      if(this.formData.zzten.length == 0) return this.$message.error('您填写的资料不完善');
      let valid = true;
      if (this.wtcdShow && this.formData.wtcd == '') {
        valid = false;
      } else if (this.wzcdShow && this.formData.wzcd == '') {
        valid = false;
      } else if (this.aqcdShow && this.formData.aqcd == '') {
        valid = false;
      } else if (this.fscdShow && this.formData.fscd == '') {
        valid = false;
      } else if (this.kgcdShow && this.formData.kgcd == '') {
        valid = false;
      } else if (this.kkcdShow && this.formData.kkcd == '') {
        valid = false;
      }
      if (!valid) return this.$message.error('您填写的资料不完善');
      if(!this.formData.img) return this.$message.error('请上传舌象照片');

      this.submitBtn = true;
      var data = this.formData;
      data.token = this.token;
      data.wt = data.wt.toString();
      data.zzfour = data.zzfour.toString();
      data.zznine = data.zznine.toString();
      data.zzten = data.zzten.toString();
      
      var res = await this.$api.post('/index/doctorassessment', data);
      if (res.error_code == 0) {
        setItem('teach', res.result);
        var type = 0;
        if(this.formData.wt[0] === '0' && this.formData.zzfour[0] === '0' && this.formData.zznine[0] === '0' && this.formData.zzten[0] === '0'){
          type = 0;
        }else{
          type = 1;
        }
        this.$router.push('/teach-result?type=' + type);
      }else if (res.error_code == 1) {
        this.$message.error(res.msg)
      }
      this.submitBtn = false;
    },
    onWtChange() {
      let _self = this;
      this.$nextTick(function() {
        _self.wzcdShow = false;
        _self.wtcdShow = false;
        // console.log(_self.formData.wt);
        //选择无 取消其他选项
        if (_self.formData.wt.includes('0')) {
          if (_self.oldWt.length == 1 && _self.oldWt[0] == '0') {
            _self.formData.wt.splice(_self.formData.wt.findIndex(item => item === '0'), 1);
          } else {
            _self.formData.wt = ['0'];
          }
        }
        //选择1-4 隐藏胃胀
        if (_self.formData.wt.includes('1') || _self.formData.wt.includes('2') || _self.formData.wt
          .includes('3') || _self.formData.wt.includes('4')) {
          _self.wtcdShow = true;
        }
        if (_self.formData.wt.includes('5')) {
          _self.wzcdShow = true;
        }
        _self.oldWt = _self.formData.wt;
      })
    },
    onZzfourChange() {
      let _self = this;
      this.$nextTick(function() {
        _self.aqcdShow = false;
        _self.fscdShow = false;
        _self.kgcdShow = false;
        _self.kkcdShow = false;
        // console.log(_self.formData.zzfour);
        //选择无、口臭 取消其他选项
        if (_self.formData.zzfour.includes('0')) {
          if (_self.oldZzfour.length == 1 && _self.oldZzfour[0] == '0') {
            _self.formData.zzfour.splice(_self.formData.zzfour.findIndex(item => item === '0'), 1);
          } else {
            _self.formData.zzfour = ['0'];
          }
        } else if (_self.formData.zzfour.includes('5')) {
          if (_self.oldZzfour.length == 1 && _self.oldZzfour[0] == '5') {
            _self.formData.zzfour.splice(_self.formData.zzfour.findIndex(item => item === '5'), 1);
          } else {
            _self.formData.zzfour = ['5'];
          }
        }
        //选择1-4
        if (_self.formData.zzfour.includes('1')) {
          _self.aqcdShow = true;
        }
        if (_self.formData.zzfour.includes('2')) {
          _self.fscdShow = true;
        }
        if (_self.formData.zzfour.includes('3')) {
          _self.kgcdShow = true;
        }
        if (_self.formData.zzfour.includes('4')) {
          _self.kkcdShow = true;
        }
        _self.oldZzfour = _self.formData.zzfour;
      })
    },
    onZznineChange() {
      let _self = this;
      this.$nextTick(function() {
        //选择无 取消其他选项
        if (_self.formData.zznine.includes('0')) {
          if (_self.oldZznine.length == 1 && _self.oldZznine[0] == '0') {
            _self.formData.zznine.splice(_self.formData.zznine.findIndex(item => item === '0'), 1);
          } else {
            _self.formData.zznine = ['0'];
          }
        }
        _self.oldZznine = _self.formData.zznine;
      })
    },
    onZztenChange(){
      let _self = this;
      this.$nextTick(function() {
        //选择无 取消其他选项
        if (_self.formData.zzten.includes('0')) {
          if (_self.oldZzten.length == 1 && _self.oldZzten[0] == '0') {
            _self.formData.zzten.splice(_self.formData.zzten.findIndex(item => item === '0'), 1);
          } else {
            _self.formData.zzten = ['0'];
          }
        }
        _self.oldZzten = _self.formData.zzten;
      })
    },
    uploadImg(url){
      this.formData.img = url;
    },
    removeImg(value){
      this.formData.img = '';
    },
  }
}
</script>

<style scoped lang="scss">
  .pr{
    position: relative;
    z-index: 2;
    .box{
      background-color: #FFFFFF;
      border-radius: 20px;
      padding-bottom: 120px;
      .shili{
        width: 148px;
        height: 148px;
        position: relative;
        border-radius: 6px;
        margin-left: 20px;
        overflow: hidden;
        span{
          width: 48px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          background: #000000;
          border-radius: 0px 0px 8px 0px;
          opacity: 0.4;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          color: #FFFFFF;
          font-size: 12px;
        }
        img{
          width: 100%;
          height: 100%;
        }
      }
      .btn{
        width: 240px;
        height: 50px;
        background: #857BFE;
        border-radius: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        padding: 0;
      }
      /deep/ .el-form-item{
        margin-bottom: 60px;
        .el-checkbox, .el-radio{
          display: block;
          line-height: 1;
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .hd{
        width: 100%;
        height: 115px;
        border-bottom: 1px solid #EBEBEB;
        img{
          margin-right: 10px;
        }
      }
    }
  }
  .img3{
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
</style>
